import image1 from "./image 1.png";
import image2 from "./image 2.png";
import image5 from "./image 5.png";
import image6 from "./image 6.png";
import image7 from "./image 7.png";
import image8 from "./image 8.png";
import image9 from "./image 9.png";

const images = {
  image1,
  image2,
  image5,
  image6,
  image7,
  image8,
  image9,
};

export default images;
