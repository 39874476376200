import React, { useState } from "react";
import { Map, Marker } from "@vis.gl/react-google-maps";
import styles from "./CustomMap.module.css";

const CustomMap = () => {
  const [markerLocation, setMarkerLocation] = useState({
    lat: 1.2929,
    lng: 103.8511,
  });

  return (
    <div className={styles["map-container"]}>
      <Map
        style={{ borderRadius: "20px" }}
        defaultZoom={13}
        defaultCenter={markerLocation}
        gestureHandling={"greedy"}
        disableDefaultUI
      >
        <Marker position={markerLocation} />
      </Map>
    </div>
  );
};

export default CustomMap;
