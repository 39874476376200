import React from "react";
import styles from "./Leadership.module.css";
import { Col, Row, Tab, Tabs } from "react-bootstrap";

const Leadership = () => {
  return (
    <div className={styles["leadership"]}>
      <div className={styles["leadership-title"]}>Our Leadership</div>
      <div className={styles["leadership-content"]}>
        <Tabs
          defaultActiveKey="home"
          transition={false}
          className="mb-3"
          style={{
            border: 0,
            width: "100%",
            gap: "1.5rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tab
            eventKey="home"
            title="Board of Directors"
            className={styles["tab-content"]}
            style={{ border: 0, width: "100%" }}
          >
            <Row
              style={{ marginBottom: "1.5rem", paddingTop: "3.5rem" }}
              className={styles["leadership-row"]}
            >
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="profile"
            title="Company Executives"
            style={{ border: 0, width: "100%", paddingTop: "3.5rem" }}
          >
            <Row style={{ marginBottom: "1.5rem" }}>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>

              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
              <Col className={styles["card-col"]}>
                <div className={styles.card}>
                  <div className={styles.overlay}>
                    <div className={styles.surname}>Name</div>
                    <div className={styles.jobposition}>COO</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Leadership;
