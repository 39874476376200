import React, { useState } from "react";
import styles from "./AboutUs.module.css";
import { Button, Carousel, Col, Fade, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LineLeft } from "../../assets/images/LineLeft.svg";
import { ReactComponent as LineRight } from "../../assets/images/LineRight.svg";
import { ReactComponent as Tree } from "../../assets/images/Tree.svg";
import { ReactComponent as LineLeftMobile } from "../../assets/images/LineLeftMobile.svg";
import { ReactComponent as LineRightMobile } from "../../assets/images/LineRightMobile.svg";
import { ReactComponent as TreeMobile } from "../../assets/images/TreeMobile.svg";
import Banner1 from "../../assets/images/Banner1.jpg";
import Banner2 from "../../assets/images/Banner2.jpg";
import Banner3 from "../../assets/images/Banner3.jpg";
import BrandStory from "../../assets/images/BrandStory.jpg";
import FadeInSection from "../FadeInSection";

const AboutUs = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles["modal-body"]}>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12 mb-3">
                  <img
                    src="./placeholder.jpg"
                    className="img-fluid"
                    alt="placeholder"
                  />
                </div>
                <div className="col-md-6 col-12">
                  <h5>NAME</h5>
                  <h6>Job Position</h6>
                  <p style={{ marginTop: "2rem" }}>
                    Lorem ipsum dolor sit amet consectetur. Elementum ac
                    habitant eget sed lobortis feugiat leo consectetur
                    adipiscing. In eget arcu fringilla varius nulla sodales. Vel
                    libero tortor nunc lorem pharetra lorem egestas. Arcu
                    gravida ultrices vitae adipiscing feugiat suspendisse sed
                    nisl viverra. Viverra ut dui nisi volutpat sit facilisis
                    cursus volutpat. Nisl nulla phasellus nullam sodales
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className={styles["about-us-container"]}>
        <div className={styles["carousel-container"]}>
          <Carousel className={styles.carousel} data-bs-theme="dark">
            <Carousel.Item className={styles["carousel-item"]}>
              <img src={Banner1} alt="slide"></img>
            </Carousel.Item>
            <Carousel.Item className={styles["carousel-item"]}>
              <img src={Banner2} alt="slide"></img>
            </Carousel.Item>
            <Carousel.Item className={styles["carousel-item"]}>
              <img src={Banner3} alt="slide"></img>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className={styles["brand-story"]}>
          <FadeInSection>
            <div className={styles["brand-story-title"]}>Our Brand Story</div>
          </FadeInSection>
          <div className={styles["brand-story-content"]}>
            <Row>
              <Col xs={12} md={6}>
                <FadeInSection delay="0.3s">
                  <div className={styles["brand-story-image"]}>
                    <img src={BrandStory} alt="placeholder-img"></img>
                  </div>
                </FadeInSection>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <FadeInSection delay="0.5s">
                  <div className={styles["brand-story-text"]}>
                    <p>
                      Sentosa Link bridges Myanmar and Singapore, empowering
                      Burmese entrepreneurs to sell their products in Singapore.
                      Our platform enriches Singapore's market with authentic
                      Burmese goods, creating jobs and boosting trade. For
                      Myanmar, it offers global market access, fostering
                      economic growth and cultural exchange, benefiting both
                      societies through enhanced connections and opportunities.
                    </p>
                  </div>
                </FadeInSection>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles["leadership"]}>
          <FadeInSection>
            <div className={styles["leadership-title"]}>Our Leadership</div>
          </FadeInSection>
          <FadeInSection delay="0.3s">
            <span className={styles["leadership-description"]}>
              Lorem ipsum dolor sit amet consectetur. Arcu laoreet felis
              pharetra tortor duis. Nulla mattis tellus pellentesque consectetur
              magna in et vulputate egestas. Tortor ut erat viverra pretium
              tristique non molestie neque.
            </span>
          </FadeInSection>

          <div className={styles["leadership-content"]}>
            <Row>
              <Col xs={12} md={6}>
                <FadeInSection delay="0.5s">
                  <div className={styles["leadership-item"]}>
                    <div className={styles["leadership-image"]}>
                      <imgs></imgs>
                    </div>
                    <div className={styles["leadership-name"]}>
                      Board of Directors
                    </div>
                    <span className={styles["leadership-description"]}>
                      Lorem ipsum dolor sit amet consectetur. Arcu laoreet felis
                      pharetra tortor duis. Nulla mattis tellus pellentesque
                      consectetur magna in et vulputate egestas.
                    </span>
                    <Button
                      variant="custom"
                      className="custom-btn"
                      onClick={() => navigate("./leadership")}
                    >
                      Read More
                    </Button>
                  </div>
                </FadeInSection>
              </Col>

              <Col xs={12} md={6}>
                <FadeInSection delay="0.7s">
                  <div className={styles["leadership-item"]}>
                    <div className={styles["executives-image"]}></div>
                    <div className={styles["leadership-name"]}>
                      Company Executives
                    </div>
                    <span className={styles["leadership-description"]}>
                      Lorem ipsum dolor sit amet consectetur. Arcu laoreet felis
                      pharetra tortor duis. Nulla mattis tellus pellentesque
                      consectetur magna in et vulputate egestas.
                    </span>
                    <Button
                      variant="custom"
                      className="custom-btn"
                      onClick={() => navigate("./leadership")}
                    >
                      Read More
                    </Button>
                  </div>
                </FadeInSection>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles["organization-structure"]}>
          <FadeInSection>
            <div className={styles["organization-title"]}>
              Organization Structure
            </div>
          </FadeInSection>
          <FadeInSection delay="0.3">
            <span className={styles["organization-description"]}>
              Lorem ipsum dolor sit amet consectetur. Arcu laoreet felis
              pharetra tortor duis. Nulla mattis tellus pellentesque consectetur
              magna in et vulputate egestas. Tortor ut erat viverra pretium
              tristique non molestie neque.
            </span>
          </FadeInSection>
        </div>
        <div className={styles["organization-wrapper"]}>
          <div className={styles["organization-content"]}>
            <FadeInSection delay="0.6">
              <div className={styles["team-member-row1"]}>
                <div
                  className={styles["team-member1"]}
                  onClick={() => setModalShow(true)}
                >
                  <div className={styles["team-member-image1"]}></div>
                  <div className={styles["team-member-info1"]}>
                    <div className={styles["team-member-name1"]}>
                      NAME SURNAME
                    </div>
                    <span className={styles["team-member-position1"]}>
                      Job Position
                    </span>
                  </div>
                  <LineLeft className={styles["line-left"]} />
                  <LineRight className={styles["line-right"]} />
                  <LineLeftMobile className={styles["line-left-mobile"]} />
                  <LineRightMobile className={styles["line-right-mobile"]} />
                </div>
              </div>
            </FadeInSection>
            <FadeInSection delay="0.7">
              <div
                className={styles["team-member-row2"]}
                style={{ paddingInline: "0.3rem" }}
              >
                <div className={styles["team-member-row2-col1"]}>
                  <div
                    className={styles["team-member2"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image2"]}></div>
                    <div className={styles["team-member-info2"]}>
                      <div className={styles["team-member-name2"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position2"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles["team-member-row2-col2"]}>
                  <div
                    className={styles["team-member2"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image2"]}></div>
                    <div className={styles["team-member-info2"]}>
                      <div className={styles["team-member-name2"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position2"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
            <FadeInSection delay="0.8">
              <div className={styles["team-member-row3"]}>
                <div className={styles["team-member-row3-col1"]}>
                  <Tree className={styles["tree-left"]} />
                  <TreeMobile className={styles["tree-mobile"]} />
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles["team-member-row3-col2"]}>
                  <Tree className={styles["tree-right"]} />
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
          </div>
        </div>

        <div className={styles["how-we-work"]}>
          <FadeInSection>
            <div className={styles["how-we-work-title"]}>How We Work</div>
          </FadeInSection>
          <div className={styles["how-we-work-content"]}>
            <Row>
              <Col xs={12} md={6}>
                {" "}
                <FadeInSection delay="0.3s">
                  <div className={styles["how-we-work-image"]}>
                    <img src="/placeholder.jpg" alt="placeholder-img"></img>
                  </div>
                </FadeInSection>
              </Col>
              <Col xs={12} md={6}>
                <FadeInSection delay="0.5s">
                  {" "}
                  <div className={styles["how-we-work-text"]}>
                    <p>
                      <span className={styles["lorem-ipsum"]}>
                        Lorem ipsum dolor sit amet consectetur. Mollis eget
                        neque neque donec ultrices non. Imperdiet auctor commodo
                        ante integer sem ornare. Consectetur imperdiet blandit
                        ut morbi urna donec. Ornare neque odio vestibulum aenean
                        commodo sed mi senectus. Enim turpis cras sed et pretium
                        orci amet.
                      </span>
                    </p>
                  </div>
                </FadeInSection>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
