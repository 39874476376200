import React from "react";
import { Carousel } from "react-bootstrap";
import styles from "./Home.module.css";
import MultiCarousel from "react-multi-carousel"; // Renamed import
import "react-multi-carousel/lib/styles.css";
import images from "../../assets/images/partners";
import Banner1 from "../../assets/images/Banner1.jpg";
import Banner2 from "../../assets/images/Banner2.jpg";
import Banner3 from "../../assets/images/Banner3.jpg";
import FadeInSection from "../FadeInSection";

const responsive = {
  extraLargeDesktop: {
    breakpoint: { max: 4000, min: 1600 },
    items: 6,
  },
  superLargeDesktop: {
    breakpoint: { max: 1600, min: 1200 },
    items: 6,
  },
  largeDesktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const Home = () => {
  return (
    <div>
      <div className={styles["carousel-container"]}>
        <Carousel className={styles.carousel} data-bs-theme="dark">
          <Carousel.Item className={styles["carousel-item"]}>
            <img src={Banner1} alt="slide"></img>
          </Carousel.Item>
          <Carousel.Item className={styles["carousel-item"]}>
            <img src={Banner2} alt="slide"></img>
          </Carousel.Item>
          <Carousel.Item className={styles["carousel-item"]}>
            <img src={Banner3} alt="slide"></img>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className={styles["partners-in-collaboration"]}>
        <FadeInSection>
          <div
            className={styles["content-title"]}
            style={{ marginBottom: "3.5rem" }}
          >
            Partners in Collaboration
          </div>
        </FadeInSection>
        <div style={{ width: "100%" }}>
          <MultiCarousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={false}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            containerClass="carousel-container"
            // partialVisible={true}
            customTransition="transform 500ms ease-in-out"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            //centerMode={true}
            rewind={true}
            slidesToSlide={1}
            rewindWithAnimation={true}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            focusOnSelect={true}
            // itemClass="carousel-item-padding-40-px"
          >
            <div className={styles["partner-logo"]}>
              <img alt="partner-logo" src={images.image1} />
            </div>
            <div className={styles["partner-logo"]}>
              <img alt="partner-logo" src={images.image2} />
            </div>
            <div className={styles["partner-logo"]}>
              <img alt="partner-logo" src={images.image5} />
            </div>
            <div className={styles["partner-logo"]}>
              <img alt="partner-logo" src={images.image6} />
            </div>
            <div className={styles["partner-logo"]}>
              <img alt="partner-logo" src={images.image7} />
            </div>
            <div className={styles["partner-logo"]}>
              <img alt="partner-logo" src={images.image8} />
            </div>
            <div className={styles["partner-logo"]}>
              <img alt="partner-logo" src={images.image9} />
            </div>
          </MultiCarousel>
        </div>
      </div>
      <div className={styles["why-choose-us"]}>
        <FadeInSection >
          <div className={styles["content-title"]}>
            Why choose us over others?
          </div>
        </FadeInSection>
        <FadeInSection delay="0.3s">
          <span className={styles["content-text"]}>
            "Choose Sentosa Link for seamless convenience, quality service, and
            strategic locations across Singapore. Our digital distribution
            platform connects Myanmar's finest products to the Singaporean
            market, while our reliable door-to-door express cargo services
            ensure hassle-free shipping between Singapore and major Myanmar
            cities. Experience unparalleled access, efficiency, and customer
            care that sets us apart."
          </span>
        </FadeInSection>
      </div>
    </div>
  );
};

export default Home;
