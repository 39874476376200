import React, { useState, useRef, useEffect } from 'react';

const FadeInSection = ({ children, delay = '0s' }) => {
  const [hasPlayed, setHasPlayed] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(true);
          if (!hasPlayed) {
            setHasPlayed(true);
          }
        }
      });
    });

    const currentRef = domRef.current;
    observer.observe(currentRef);

    return () => {
      observer.unobserve(currentRef);
    };
  }, [hasPlayed]);

  return (
    <div
      className={`fade-in-section ${isVisible && hasPlayed ? 'is-visible' : ''}`}
      style={{ '--fade-delay': delay }}
      ref={domRef}
    >
      {children}
    </div>
  );
};

export default FadeInSection;
