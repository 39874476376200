import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <Container fluid className={styles.container}>
      <Row className={styles.footer}>
        <Col xs={12} sm={6} md={4} className={styles.section}>
          <h3 className={styles.title}>Contact Us</h3>
          <p className={styles.text}>
            No 621, Yoma Yeik Thar Street, Kamayut Township, Yangon, Myanmar
          </p>
          <p className={styles.text}>hello@marathonmyanmar.com</p>
          <p className={styles.text}>+95 9 777 241 334</p>
        </Col>

        <Col xs={12} sm={6} md={2} className={styles.section}>
          <h3 className={styles.title}>Quick Link</h3>
          <p className={styles.text}>Quick Link 1</p>
          <p className={styles.text}>Quick Link 2</p>
          <p className={styles.text}>Quick Link 3</p>
          <p className={styles.text}>Quick Link 4</p>
        </Col>

        <Col xs={12} sm={6} md={2} className={styles.section}>
          <h3 className={styles.title}>Quick Link</h3>
          <p className={styles.text}>Quick Link 1</p>
          <p className={styles.text}>Quick Link 2</p>
          <p className={styles.text}>Quick Link 3</p>
          <p className={styles.text}>Quick Link 4</p>
        </Col>

        <Col xs={12} sm={6} md={2} className={styles.section}>
          <h3 className={styles.title}>Quick Link</h3>
          <p className={styles.text}>Quick Link 1</p>
          <p className={styles.text}>Quick Link 2</p>
          <p className={styles.text}>Quick Link 3</p>
          <p className={styles.text}>Quick Link 4</p>
        </Col>
      </Row>

      <Row className={styles.copyright}>
        <Col xs={12} style={{ margin: 0 }}>
          <span className={styles["copyright-text"]}>
            Copyright© 2024 Sentosa link. All rights reserved.
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
