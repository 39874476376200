import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Brands, Layout, Leadership, SupplierPartners } from "./components";
import { Home, AboutUs, ContactUs, OurTechnology } from "./components";
import "./App.css";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        Component: Home,
      },
      {
        path: "about_us",
        Component: AboutUs,
      },
      {
        path: "about_us/leadership",
        Component: Leadership,
      },
      {
        path: "brands",
        Component: Brands,
      },
      {
        path: "supplier_partners",
        Component: SupplierPartners,
      },
      {
        path: "our_technology",
        Component: OurTechnology,
      },
      {
        path: "contact_us",
        Component: ContactUs,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
