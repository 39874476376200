import React from "react";
import styles from "./ContactUs.module.css";
import { Button, Form, Row, Col } from "react-bootstrap";
import CustomMap from "./CustomMap";
import { ReactComponent as MapPinOutlined } from "../../assets/icons/MapPinOutlined.svg";
import { ReactComponent as PhoneFilled } from "../../assets/icons/PhoneFilled.svg";
import { ReactComponent as EmailFilled } from "../../assets/icons/EmailFilled.svg";
import { ReactComponent as Facebook } from "../../assets/icons/Facebook.svg";
import { ReactComponent as Messenger } from "../../assets/icons/Messenger.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/Whatsapp.svg";
import { ReactComponent as TikTok } from "../../assets/icons/TikTok.svg";
import FadeInSection from "../FadeInSection";

const ContactUs = () => {
  return (
    <div className={styles["contact-info"]}>
      <Row className="justify-content-between" style={{ width: "100%" }}>
        <Col xs={12} md={5}>
          <FadeInSection>
            <span className={styles["contact-form-title"]}>
              Love to hear from you, Get in touch 👋
            </span>
          </FadeInSection>
          <div className={styles["contact-form"]}>
            <FadeInSection delay="0.2s">
              <Form style={{ marginBottom: "2.5rem" }}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Your Email</Form.Label>
                  <Form.Control type="email" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control as="textarea" rows={4} />
                </Form.Group>
              </Form>
            </FadeInSection>
            <FadeInSection delay="0.35s">
              <Button variant="custom" className="custom-btn">
                Send Message
              </Button>
            </FadeInSection>
          </div>
        </Col>
        <Col xs={12} md={{ span: 5 }}>
          <FadeInSection delay="0.5s">
            <CustomMap />
          </FadeInSection>
          <FadeInSection delay="0.6s">
            <div className={styles["contact-details"]}>
              <div>
                <span
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    marginRight: "0.94rem",
                  }}
                >
                  <MapPinOutlined />
                </span>
                <span>
                  111, North Bridge Road, 02-02B Peninsula Plaza, 179098
                </span>
              </div>
              <div>
                <span
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    marginRight: "0.94rem",
                  }}
                >
                  <PhoneFilled />
                </span>
                <span>+66 9881 2787</span>
              </div>
              <div>
                <span
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    marginRight: "0.94rem",
                  }}
                >
                  <EmailFilled />
                </span>
                <span>hello@sentosalink.com</span>
              </div>
            </div>
          </FadeInSection>
          <FadeInSection delay="0.7s">
            <div className={styles["contact-socials"]}>
              <a href="https://www.facebook.com/share/nfUDuVo11cYyJZdx/?mibextid=LQQJ4d">
                <div className={styles["social-icons"]}>
                  <Facebook />
                </div>
              </a>
              <a href="http://m.me/sentosalink">
                <div className={styles["social-icons"]}>
                  <Messenger />
                </div>
              </a>
              <a href="https://wa.me/6598812787">
                <div className={styles["social-icons"]}>
                  <Whatsapp />
                </div>
              </a>
              <a href="#">
                <div className={styles["social-icons"]}>
                  <TikTok />
                </div>
              </a>
            </div>
          </FadeInSection>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
