import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
// import Navbar from "./Navbar";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`${styles["header-container"]} ${
        isScrolled ? styles.scrolled : ""
      }`}
    >
      <Navbar expand="lg" className="bg-body-white" style={{ padding: 0 }}>
        <Container style={{ paddingInline: "1.5rem" }} className="container">
          <Navbar.Brand href="/">
            <img alt="sentosa-link" src="/logo.svg" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <span className={styles.navList}>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                >
                  Home
                </NavLink>
              </span>
              <span className={styles.navList}>
                <NavLink
                  to="/about_us"
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                >
                  About Us
                </NavLink>
              </span>
              <span className={styles.navList}>
                <NavLink
                  to="/our_technology"
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                >
                  Our Technology
                </NavLink>
              </span>
              <span className={styles.navList}>
                <NavLink
                  to="/contact_us"
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                >
                  Contact Us
                </NavLink>
              </span>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
